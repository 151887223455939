<template>
 <div :class="windowClass">
   <v-container>
    <v-row>
      <v-col cols="12">
                <v-card>
               <v-toolbar>
                 <v-icon left color="primary">mdi-account-multiple-check</v-icon> {{event.name}} |  {{$moment(event.event_date).format("MMM, DD YYYY")}}
                 <v-spacer/>
                 <v-divider vertical inset class="ml-5 mr-2"/>
                 {{event.venue}}
                 <v-spacer/>
                 <v-divider vertical inset class="ml-5 mr-2"/>
                 {{event.status}}
                </v-toolbar>
               <v-card-text>
                <table class="va-table va-table-border-bot ">
                  <tr>
                    <th width="50">No.</th>
                    <th>Name</th>
                    <th v-for="f in event.num_fights" :key="f+'-e'">F{{f}}</th>
                    
                    <th class="text-center">SCORE</th>
                   
                  </tr>
                  <template v-if="canedit">
                  <tr v-for="(item, index) in entries" :key="index+'-t'">
                    <td class="pa-1">{{item.entry_no}}</td>
                    <td><v-text-field dense  hide-details v-model="item.name" @change="save(item)" :disabled="event.status!='Upcoming'"/></td>
                    <td width="80"  v-for="(fight, f) in item.fights" :key="f+'-f'">
                      <v-text-field dense  hide-details v-model="item.fights[f]"  @change="save(item)"/> 
                    </td>
                    <!-- <td width="80"><v-select dense  hide-details v-model="item.num2"  :items="['0','1']" @change="save(item)"/></td>
                    <td width="80"><v-select dense  hide-details v-model="item.num3"  :items="['0','1']" @change="save(item)"/></td>
                    <td width="80"><v-select dense  hide-details v-model="item.num4"  :items="['0','1']" @change="save(item)"/></td>
                    <td width="80"><v-select dense  hide-details v-model="item.num5"   :items="['0','1']" @change="save(item)"/></td> -->
                    <td class="text-center font-weight-bold">{{getScore(item)}}</td>
                  </tr>
                </template>
                <template v-else>
                  <tr v-for="(item, index) in entries" :key="index">
                    <td class="pa-1">{{item.entry_no}}</td>
                    <td>{{item.name}}</td>
                    <td width="80"  v-for="(fight, f) in item.fights" :key="f+'-f'">
                      {{item.fights[f]}}
                    </td>
                    <td class="text-center font-weight-bold">{{getScore(item)}}</td>
                  </tr>
                </template>
                  
                </table>
                <v-toolbar dense class="mt-8" elevation="0" v-if="canedit && event.status=='Upcoming'">
                  <v-text-field v-model="entryname" label="Entry Name" dense outlined hide-details append-outer-icon="mdi-send" @click:append-outer="addEnrty()"/>
                </v-toolbar>
               
               </v-card-text>
             </v-card>
          </v-col>
         
    </v-row>
    <v-toolbar dense class="mt-2 mb-2">
        <span class="text-h6">SUMMARY</span>
        <v-spacer></v-spacer>
        <v-btn small color="info" @click="dailog=true">View Details</v-btn>
        <v-btn small :loading="loading" color="success" class="ml-2" v-if="event.status=='Closed'" @click="confirm=true">Generate Winners</v-btn>
      </v-toolbar>
    <v-row>
      <v-col cols="12">
        <div class="pa-1 font-weight-bold">GROSS: {{$money(TOTAL)}}</div>
        <v-divider/>
        <div class="pa-1 font-weight-bold">No. Of Bets: {{$count(bets.length)}}</div>
        <v-divider/>
          <table class="va-table va-table-border-bot" v-for="(pick, i) in event.picks" :key="i">
                  <tr :key="i">
                  <th colspan="5" class="text-left" style="background: #D8F4FF; padding: 4px">{{pick}}</th>
                  </tr>
                  <tr>
                    <th>BRACKET</th>
                    <th># of BETS</th>
                    <th>Gross</th>
                    <th>PAYOUT</th>
                   
                  </tr>
                  <tr v-for="(bracket, index) in event.brackets" :key="index + '-' + i">
                    <td class="text-left" style="">{{bracket.group}}</td>
                    <td class="text-left" style="">{{$count(getNumBets(bracket.group, pick))}}</td>
                    <td class="text-left" style="">{{$money(getTotal(bracket.group, pick))}}</td>
                    <td class="text-left" style="">{{$money(getPayout(bracket.group, pick))}}</td>
                  
                  </tr>    
          </table>
      </v-col> 
    </v-row>    
   
    </v-container>
    <va-confirm :show="confirm" cancelbtn="Not Now" okbtn="Process Event Result" :message="'Would you like process this event?'" @ConfirmEvent="confirmEvent" />
    <va-bet-summary :show="dailog" :event_id="event.id" @DialogEvent="dailog=false" />
  </div>
</template>
<script>

import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
        form: false,
        loading: false,
        dailog: false,
        entryname:'',
        confirm: false,
      event:{},
      bets:[],
        entries:[],
        hash: null,
        headers: [
         { text: ''},
            { text: 'Event', value:"name"},
            { text: 'Venue', value:"venue"},
            { text: 'Date', value:"event_date"},
            { text: 'Percentage', value:"venue"},
            { text: 'created', value:"created_at"},
            { text: 'Status', value:"status"}
        ]
    }),
    beforeCreate: function () {
      if (!this.$session.exists()) {
        this.$router.push('/')
      }
    },
    created() {
       if (this.$session.exists()) {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get('jwt')
          this.setLoggedIn(true) 
          this.getEntries()
          this.getEvent()
          
        }
    },
    computed:{
       windowClass() {
        if(this.$store.state.responsive){
          return "va-reponsive"
        } else {
          return "va-not-reponsive"
        }
      },
      TOTAL(){
        var total = this.bets.reduce((res, item)=>{
             res = res + this.$IsNum(item.amount)
             return res
           }, 0)
          return total
      },
      BYBRACKETS() {
        var allbets = this.$groupBy('bracket')
        return allbets(this.bets)
       },
      canedit() {
         if(this.event.status) {
          if(this.event.status=='Upcoming' ||  this.event.status=='Closed') {
            return true
          }
            return false
         }
        return true
       
      }
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn']),
      generate(){  
        this.loading = true
        this.$http.post("event/process",this.event ).then(response => {
          this.loading = false
          response.data.status? this.VA_ALERT('success', response.data.message): this.VA_ALERT('error', response.data.message)
          this.getEvent()
        }).catch(e => {
        console.log(e.data)
        })
     },
      confirmEvent(e) {
        this.confirm = false
        if(e=='ok') {
          this.generate()
        }
      },
      getTotal(_group, pickname) {
        var bets = this.getStats(_group, pickname)
       // console.log("test", bets)
        var total = bets.reduce((res, item)=>{
          res =res + this.$IsNum(item.amount)  
          return res
        }, 0)
        return total 
       },
      getNumBets(_group, pickname) {
        var bets = this.getStats(_group, pickname)
        return bets.length
       },
      getPayout(_group, pickname) {
        var bets = this.getStats(_group, pickname)
        //console.log("test", bets)
        var total = bets.reduce((res, item)=>{
          res =res + this.$IsNum(item.amount)  
          return res
        }, 0)
        return total * ((100 - this.$IsNum(this.event.percentage)))/100
       },
       getStats(_group, pickname) {
          for (let [key, value] of Object.entries(this.BYBRACKETS)) {
            if(_group == key) {
               var picks = this.$groupBy('pick')
               var _obj = picks(value)
              return _obj[pickname]?_obj[pickname]:[]
            } 
          }
          return []
       },
      getBets(status){  
        this.$http.post("sidebet/qry", {event_id: this.$IsNum(this.$route.params.id), status: status} ).then(response => {
            response.data.items != null?this.bets = response.data.items:this.bets = []
        }).catch(e => {
        console.log(e.data)
        })
     },
      getScore(entry){  
        var score = 0
        for(var i = 0; i < entry.fights.length;i++) {
          score = score + this.$IsNum(entry.fights[i])
        }
        return score.toString()
      },
      initFights(){
        var num_fights = this.$IsNum(this.event.num_fights)
        var fights = []
        for(var i = 0; i < num_fights;i++) {
          fights.push("0")
        }
        return fights
     },
      addEnrty: function() { 
        let entryno = this.$IsNum(this.entries.length) + 1
        let name = this.entryname.trim().toUpperCase()
         let fights = this.initFights()
         console.log("New entry", {event_id: this.$IsNum(this.$route.params.id), name: name, entry_no: entryno, fights:fights} )
        this.$http.post("entry/add", {event_id: this.$IsNum(this.$route.params.id), name: name, entry_no: entryno, fights:fights} ).then(response => {
           this.getEntries()
           response.data.status? this.VA_ALERT('success', response.data.message): this.VA_ALERT('error', response.data.message)
           this.entryname = ""
        }).catch(e => {
        console.log(e.data)
        })
     },
      save(entry) {
      
        entry.score = this.getScore(entry)
        console.log(entry)
        this.$http.post("entry/update", entry).then(response => {
            response.data.status? this.VA_ALERT('success', response.data.message): this.VA_ALERT('error', response.data.message)
            this.getEntries()
        }).catch(e => {
        console.log(e)
        })
      },
      getEntries: function() { 
        this.$http.post("entry/get", {event_id: this.$IsNum(this.$route.params.id)} ).then(response => {
            response.data.items != null?this.entries = response.data.items:this.entries = []
            console.log(this.entries)
        }).catch(e => {
        console.log(e.data)
        })
     },
     Save: function() {
        let method = 'event/update'
      
        if(this.state == 'new') { 
          this.event.event_key = this.event.name+'-'+this.event.date+'-'+this.event.venue
           let event_key = this.event.name+'-'+this.event.venue
           this.event.event_key = event_key.replace(/\s/g, "-")
        }
        this.$http.post(method, this.event).then(response => {
            response.data.status? this.VA_ALERT('success', response.data.message): this.VA_ALERT('error', response.data.message)
            this.getItems()
        }).catch(e => {
        console.log(e)
        })
     },
     getEvent: function() { 
        this.$http.post("event/get", {id: this.$IsNum(this.$route.params.id)} ).then(response => {
            response.data.item != null?this.event = response.data.item:this.event = {}
            this.getBets('all')
            this.getSummaries()
        }).catch(e => {
        console.log(e.data)
        })
     },
     getSummaries(){ 
          console.log("getSummaries")
           this.$http.post("event/summary", {id:this.$IsNum(this.$route.params.id)} ).then(response => {
            console.log(response.data)
               response.data.items != null?this.summaries = response.data.items:this.summaries = []
           }).catch(e => {
           console.log(e.data)
           })
        },
    
     
    }
  }
</script>